function genderToSex(gender: string): string {
    return gender === 'MALE' ? '1' : gender === 'FEMALE' ? '0' : '';
}

function getNumberValue(input: JQuery<HTMLInputElement>): number | undefined {
    let value = input.val();

    if (typeof value === 'string') {
        value = value.trim();
        if (value) {
            const num = Number(
                value
                    .replace(',', '.')
                    .replace(' ', '')
            );

            if (Number.isNaN(num)) {
                return undefined;
            }

            return num;
        }
    }

    return undefined;
}

export { genderToSex, getNumberValue };
