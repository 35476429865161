import $ from 'jquery';

import 'components/suggestions';
import { getNumberValue } from '../../users/utils';

export const offerOrderForm = (): void => {
    let $quantity: JQuery<HTMLInputElement>;
    let price: number | undefined;
    let $priceTotal: JQuery<HTMLInputElement>;
    let $delivery: JQuery<HTMLInputElement>;
    let $deliveryAddress: JQuery<HTMLInputElement>;
    let $client: JQuery<HTMLInputElement>;
    let $email: JQuery<HTMLInputElement>;
    let $company: JQuery<HTMLInputElement>;
    let $companyInn: JQuery<HTMLInputElement>;
    let $companyOgrn: JQuery<HTMLInputElement>;
    let $companyAddress: JQuery<HTMLInputElement>;

    const numberFormat = new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', currencyDisplay: 'symbol' });

    window.addEventListener('htmx:afterOnLoad', () => {
        $quantity = $('input[name=quantity]');
        price = getNumberValue($('input[name=price]'));
        $priceTotal = $('input[name=price_total]');

        $delivery = $('input[name=delivery]');
        $deliveryAddress = $('input[name=delivery_address]');

        $client = $('input[name=client]');
        $email = $('input[name=email]');
        $company = $('input[name=company]');
        $companyInn = $('input[name=company_inn]');
        $companyOgrn = $('input[name=company_ogrn]');
        $companyAddress = $('input[name=company_address]');

        $quantity.on('change', (e) => {
            if (price) {
                $priceTotal.val(numberFormat.format(Number(e.target.value ?? 1) * price));
            }
        });

        $delivery.on('change', (e) => {
            $deliveryAddress.closest('div.cell').toggle(e.target.value !== 'PICKUP');
        });

        $client.on('change', (e) => {
            $company.closest('div.cell').toggle(e.target.value === 'LEGAL');
        });

        $deliveryAddress.suggestions({
            token: window.Elec.DaDataApiToken,
            type: 'ADDRESS',
            constraints: {
                locations: { country: '*' }
            },
            bounds: 'country-region-city-address',
        });

        $email.suggestions({
           token: window.Elec.DaDataApiToken,
           type: 'EMAIL',
        });

        $company.suggestions({
            token: window.Elec.DaDataApiToken,
            type: 'PARTY',
            params: {
                status: ['ACTIVE']
            },
            count: 10,
            onSelect: (suggestion) => {
                $company.val(suggestion.data.name.short_with_opf);
                $companyInn.val(suggestion.data.inn);
                $companyOgrn.val(suggestion.data.ogrn);
                $companyAddress.val(suggestion.data.address.value);
            },
            onSelectNothing: () => {
                $company.val('');
                $companyInn.val('');
                $companyOgrn.val('');
                $companyAddress.val('');
            }
        });
    })
};
